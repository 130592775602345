import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import Link from "../../../../utils/Link"
import Typography from "../../../ui/Typography"

export const TileStyle = (props) => css`
  display: ${props.hideOnMobile ? "none" : "flex"};
  flex-direction: column;
  height: 100%;

  ${props.theme.breakpointsLegacy.tablet} {
    display: flex;
  }
`

export const TileContainer = styled.div`
  ${TileStyle}
`

export const TileImageContainer = styled.div`
  flex: 1;
`

export const ImageText = styled.div`
  font-size: 28px;
  position: absolute;
  bottom: 0;
  padding: 0.8rem;
  margin-bottom: 50px;
  color: ${(props) =>
    props.isTextLight
      ? props.theme.colors.grayscale.white
      : props.theme.colors.primary};
`

export const TileImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

export const TileLink = styled(({ hideOnMobile, ...props }) => (
  <Link {...props} />
))`
  ${TileStyle}
  text-decoration: none;
`

export const TileContent = styled.div`
  padding: 0.8rem;
  height: 50px;
`

export const TileWrapper = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 1px;
  height: 100%;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    grid-template-columns: repeat(${(props) => props.tileCount}, 1fr);
  }
`

export const ScrollContainer = styled.div`
  position: relative;
  overflow: hidden;
`

export const NextButton = styled.div`
  position: absolute;

  top: 0;
  ${(props) => (props.prev ? "left" : "right")}: 0;

  height: 100%;
  width: 30%;
  z-index: 100;
`

export const TileSectionContainer = styled.div`
  width: 100%;
  display: ${(props) =>
    props.showOnMobile === undefined || props.showOnMobile ? `block` : `none`};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    display: ${(props) =>
      props.showOnDesktop === undefined || props.showOnDesktop
        ? `block`
        : `none`};
  }
`

export const Title = styled.span`
  ${Typography.Medium}
  color: ${(props) => props.theme.colors.primary};
`
